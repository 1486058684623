/* eslint-disable import/no-anonymous-default-export */
import { useState } from "react";
import dayjs from "dayjs";
import { initialize } from "react-ga";
import { getPayload, getPermisosByModule } from "../../utils/auth";
import Sidebar from "../new-molecules/sidebar";

import {
  HomeOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import { useNavigate } from "../../router/AppRouter";
//import { useLocation } from "../../router/AppRouter";
import {
  Layout,
  Row,
  Typography,
  Card,
  Space,
  notification,
  Breadcrumb,
  Badge,
  Col,
  Tag,
  Button,
} from "antd";
import { useHotkeys } from "react-hotkeys-hook";
import CommandPalette from "react-command-palette";
import { hotKeys, CommantPallete } from "./CommantPallete";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { HomeHotKeys } from "./HomeHotkeys";
import { theme } from "./theme";
import { backendGoogleAnalitycs } from "../../utils/enviroments";

const { Paragraph } = Typography;
const { Content, Footer, Sider } = Layout;

function atomCommand(suggestion: any) {
  const { name, shortcut, color } = suggestion;
  return (
    <div className="atom-item">
      <Space size="small" align="baseline">
        <Badge color={color} />
        <span>{name}</span>
        <div
          style={{
            backgroundColor: "#0cd64f",
            borderRadius: "4px",
            color: "#ffff",
            fontSize: "12px",
            marginRight: "6px",
            padding: "2px 4px",
          }}
        >
          {shortcut}
        </div>
      </Space>
    </div>
  );
}

export default (props: any) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const push = useNavigate();
  const commands = CommantPallete({ push });
  const payload = getPayload();
  initialize(backendGoogleAnalitycs || "xxxxxxxxxxxxxx");

  HomeHotKeys({ push });

  const close = () => {
    notification.destroy();
  };

  const openNotification = () => {
    const key = `open${Date.now()}`;

    const getName = payload.permisos.modulos.map((item: any) => {
      return item.short_name;
    });

    const clients: any = getPermisosByModule("clients", undefined);
    const getclients =
      clients && clients.map((permiso: any) => permiso.submodulo);

    const miscellaneous: any = getPermisosByModule("miscellaneous", undefined);
    const getmiscellaneous =
      miscellaneous && miscellaneous.map((permiso: any) => permiso.submodulo);

    const usuarios: any = getPermisosByModule("usuarios", undefined);
    const getusuarios =
      usuarios && usuarios.map((permiso: any) => permiso.submodulo);

    const assets: any = getPermisosByModule("assets", undefined);
    const getassets = assets && assets.map((permiso: any) => permiso.submodulo);

    const transacctions: any = getPermisosByModule("transacctions", undefined);
    const gettransacctions =
      transacctions && transacctions.map((permiso: any) => permiso.submodulo);

    const vertebost: any = getPermisosByModule("vertebots", undefined);
    const getVertebots =
      vertebost && vertebost.map((permiso: any) => permiso.submodulo);

    const data = [
      {
        key: "0",
        name: t("viewShortcuts"),
        shortCut: "SHIFT + TAB",
      },
      {
        key: "1",
        name: t("closeShortcuts"),
        shortCut: "SHIFT + 1",
      },
      {
        key: "2",
        name: t("beginning"),
        shortCut: "SHIFT + Q",
      },
      {
        key: "28",
        name: t("updateTable"),
        shortCut: "SHIFT + ESPACIO",
      },
      getclients && getclients.includes("place")
        ? {
            key: "3",
            name: t("point"),
            shortCut: "SHIFT + J",
          }
        : null,
      getclients && getclients.includes("account")
        ? {
            key: "4",
            name: t("account"),
            shortCut: "SHIFT + C",
          }
        : null,
      getclients && getclients.includes("regional")
        ? {
            key: "5",
            name: t("groups"),
            shortCut: "SHIFT + G",
          }
        : null,
      getmiscellaneous && getmiscellaneous.includes("domain")
        ? {
            key: "6",
            name: t("domains"),
            shortCut: "SHIFT + D",
          }
        : null,
      getmiscellaneous && getmiscellaneous.includes("rates")
        ? {
            key: "27",
            name: t("rates"),
            shortCut: "SHIFT + 4",
          }
        : null,
      gettransacctions && gettransacctions.includes("transactions")
        ? {
            key: "7",
            name: t("transactions"),
            shortCut: "SHIFT + T",
          }
        : null,
      getmiscellaneous && getmiscellaneous.includes("marketer")
        ? {
            key: "8",
            name: t("serviceProvider"),
            shortCut: "SHIFT + S",
          }
        : null,
      getmiscellaneous && getmiscellaneous.includes("bank")
        ? {
            key: "9",
            name: t("entities"),
            shortCut: "SHIFT + E",
          }
        : null,
      getmiscellaneous && getmiscellaneous.includes("payment")
        ? {
            key: "10",
            name: t("paymentMethods"),
            shortCut: "SHIFT + M",
          }
        : null,
      gettransacctions && gettransacctions.includes("fund")
        ? {
            key: "11",
            name: t("money"),
            shortCut: "SHIFT + X",
          }
        : null,
      gettransacctions && gettransacctions.includes("cashsource")
        ? {
            key: "12",
            name: t("ResourceSources"),
            shortCut: "SHIFT + O",
          }
        : null,
      gettransacctions && gettransacctions.includes("budget")
        ? {
            key: "13",
            name: t("budget"),
            shortCut: "SHIFT + R",
          }
        : null,
      getusuarios && getusuarios.includes("users")
        ? {
            key: "14",
            name: t("user"),
            shortCut: "SHIFT + U",
          }
        : null,
      getName && getName.includes("facturas")
        ? {
            key: "15",
            name: t("bill"),
            shortCut: "SHIFT + V",
          }
        : null,
      getName && getName.includes("Pagos")
        ? {
            key: "16",
            name: t("payments"),
            shortCut: "SHIFT + P",
          }
        : null,
      getName && getName.includes("Auditorias")
        ? {
            key: "17",
            name: t("audits"),
            shortCut: "SHIFT + H",
          }
        : null,
      getassets && getassets.includes("loads")
        ? {
            key: "18",
            name: t("loads"),
            shortCut: "SHIFT + A",
          }
        : null,
      getassets && getassets.includes("meters")
        ? {
            key: "19",
            name: t("meter"),
            shortCut: "SHIFT + W",
          }
        : null,
      {
        key: "20",
        name: t("search"),
        shortCut: "SHIFT + L",
      },
      getName && getName.includes("Descargas")
        ? {
            key: "21",
            name: t("reportDownloads"),
            shortCut: "SHIFT + Y",
          }
        : null,
      getName && getName.includes("Compañias")
        ? {
            key: "22",
            name: t("company"),
            shortCut: "SHIFT + K",
          }
        : null,
      getusuarios && getusuarios.includes("rols")
        ? {
            key: "23",
            name: t("roles"),
            shortCut: "SHIFT + F",
          }
        : null,
      getName && getName.includes("Dashboard facturas")
        ? {
            key: "24",
            name: t("invoiceDashboard"),
            shortCut: "SHIFT + I",
          }
        : null,
      getName && getName.includes("Dashboard pagos")
        ? {
            key: "25",
            name: t("dashboardPayments"),
            shortCut: "SHIFT + N",
          }
        : null,
      getVertebots && getVertebots.includes("vbots")
        ? {
            key: "25",
            name: t("Vertebots"),
            shortCut: "SHIFT + B",
          }
        : null,
      getVertebots && getVertebots.includes("bdownloads")
        ? {
            key: "26",
            name: t("invoiceDashboard"),
            shortCut: "SHIFT + 2",
          }
        : null,

      getName && getName.includes("Busqueda de facturas")
        ? {
            key: "27",
            name: t("invoice_search"),
            shortCut: "SHIFT + 3",
          }
        : null,
    ];
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={() => notification.close(key)}
      >
        Confirmar
      </Button>
    );
    notification.config({
      maxCount: 1,
    });
    notification.open({
      message: "ATAJOS DE TECLADO",
      duration: 5,
      icon: <span className="material-icons">shortcut</span>,
      style: {
        width: 500,
        marginTop: "10px",
      },

      description: (
        <div className="scroll-div-users">
          <Row gutter={[8, 8]}>
            {data &&
              data.map((item: any) => (
                <>
                  {item ? (
                    <Col key={item.key} span={12}>
                      <Paragraph>
                        <Paragraph style={{ fontSize: "0.9erem" }}>
                          {item.name}
                        </Paragraph>
                        <Tag style={{ fontSize: "0.9erem" }} color="blue">
                          {item.shortCut ?? ""}
                        </Tag>
                      </Paragraph>
                    </Col>
                  ) : null}
                </>
              ))}
          </Row>
        </div>
      ),
      btn,
      key,
      onClose: close,
    });
  };

  useHotkeys("shift+1", () => {
    notification.destroy();
  });
  useHotkeys("shift+tab", () => {
    openNotification();
  });

  return (
    <>
      <Layout style={{ maxWidth: "100%" }}>
        <Sider
          width={60}
          style={{ overflowY: "auto", margin: 0 }}
          collapsedWidth={60}
          collapsed={true}
        >
          <Sidebar />
        </Sider>
        <Layout style={{ minHeight: "100vh", maxWidth: "100%" }}>
          <Content>
            <Card size="small">
              <Card.Grid
                hoverable={false}
                style={{
                  minWidth: "100%",
                  maxWidth: "100%",
                  minHeight: "95vh",
                  //overflowY: "auto",
                  padding: 10,
                  margin: 0,
                }}
              >
                <Row gutter={[4, 4]}>
                  <Col span={23}>
                    <Breadcrumb>
                      <Breadcrumb.Item onClick={() => push("/home")}>
                        <Space size="small" align="baseline">
                          <HomeOutlined
                            style={{ fontSize: "0.9rem", cursor: "pointer" }}
                          />
                          <Typography.Text
                            style={{ fontSize: "0.9rem", cursor: "pointer" }}
                          >
                            {payload.company_name}
                          </Typography.Text>
                        </Space>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item onClick={() => setOpen(true)}>
                        <SearchOutlined
                          style={{ fontSize: "0.9rem", cursor: "pointer" }}
                        />
                      </Breadcrumb.Item>
                    </Breadcrumb>
                  </Col>
                  <Col span={1}>
                    <QuestionCircleOutlined
                      onClick={openNotification}
                      style={{ fontSize: "0.9rem", cursor: "pointer" }}
                    />
                  </Col>
                  <Col span={24}>
                    <Outlet />
                  </Col>
                </Row>
              </Card.Grid>
            </Card>
          </Content>
          <Footer style={{ margin: 5, padding: 0 }}>
            <Row
              justify="center"
              style={{ margin: 0, padding: "0px 10px 10px 10px" }}
            >
              <Typography.Text>{`Vertebra ©${dayjs().format(
                "YYYY"
              )} Todos los derechos reservados || smart-eco versión ${
                process.env.REACT_APP_VERSION
              }`}</Typography.Text>
            </Row>
          </Footer>
        </Layout>
      </Layout>
      <CommandPalette
        commands={commands}
        open={open}
        options={{
          allowTypo: true,
          key: "name",
          keys: ["name"],
          limit: 35,
          scoreFn: null,
          threshold: -Infinity,
        }}
        onRequestClose={() => setOpen(false)}
        placeholder="vamos a ..."
        trigger={null}
        header={
          <div
            style={{
              color: "#ffffff",
              padding: "10px 15px",
              background: "#009432",
              borderRadius: "4px",
              display: "inline-block",
              fontFamily: "arial",
              fontSize: "12px",
              marginTop: "6px",
              marginBottom: "6px",
              width: "100%",
            }}
          >
            <span style={{ paddingRight: "32px" }}>Comandos</span>
            <span style={{ paddingRight: "32px" }}>
              <kbd
                style={{
                  backgroundColor: "#68b984",
                  borderRadius: "4px",
                  color: "#ffff",
                  fontSize: "12px",
                  marginRight: "6px",
                  padding: "2px 4px",
                }}
              >
                ↑↓
              </kbd>
              Para navegar
            </span>
            <span style={{ paddingRight: "32px" }}>
              <kbd
                style={{
                  backgroundColor: "#68B984",
                  borderRadius: "4px",
                  color: "#ffff",
                  fontSize: "12px",
                  marginRight: "6px",
                  padding: "2px 4px",
                }}
              >
                enter
              </kbd>{" "}
              Para seleccionar
            </span>
            <span style={{ paddingRight: "32px" }}>
              <kbd
                style={{
                  backgroundColor: "#68B984",
                  borderRadius: "4px",
                  color: "#fff",
                  fontSize: "12px",
                  marginRight: "6px",
                  padding: "2px 4px",
                }}
              >
                esc
              </kbd>{" "}
              Para cerrar
            </span>
          </div>
        }
        closeOnSelect
        maxDisplayed={100}
        theme={theme}
        resetInputOnOpen
        reactModalParentSelector="body"
        renderCommand={atomCommand}
        hotKeys={hotKeys}
      />
    </>
  );
};

// import { FC, useEffect, useState } from "react";
// import { initialize } from "react-ga";
// import { getPayload, getPermisosByModule } from "../../utils/auth";
// import Sidebar from "../new-molecules/sidebar";

// import {
//   HomeOutlined,
//   QuestionCircleOutlined,
//   SearchOutlined,
// } from "@ant-design/icons";

// import { useNavigate } from "../../router/AppRouter";
// //import { useLocation } from "../../router/AppRouter";
// import {
//   Layout,
//   Row,
//   Typography,
//   Card,
//   Space,
//   notification,
//   Breadcrumb,
//   Col,
//   Tag,
//   Button,
// } from "antd";
// import { useHotkeys } from "react-hotkeys-hook";
// import { useTranslation } from "react-i18next";
// import { Outlet } from "react-router-dom";
// import { HomeHotKeys } from "./HomeHotkeys";
// import { backendGoogleAnalitycs } from "../../utils/enviroments";
// import CommandPalette, { filterItems, getItemIndex } from "react-cmdk";

// import "react-cmdk/dist/cmdk.css";
// import dayjs from "dayjs";

// const { Paragraph } = Typography;
// const { Content, Footer, Sider } = Layout;

// interface HomeProps {
//   props?: any;
// }

// const Home: FC<HomeProps> = ({ props }) => {
//   const { t } = useTranslation();
//   const push = useNavigate();
//   const payload = getPayload();
//   const [page, setPage] = useState<"root" | "projects">("root");
//   const [open, setOpen] = useState<boolean>(false);
//   const [search, setSearch] = useState("");

//   useEffect(() => {
//     function handleKeyDown(e: KeyboardEvent) {
//       if (
//         (navigator?.platform?.toLowerCase().includes("mac")
//           ? e.metaKey
//           : e.ctrlKey) &&
//         e.key === "k"
//       ) {
//         e.preventDefault();
//         e.stopPropagation();

//         setOpen((currentValue) => {
//           return !currentValue;
//         });
//       }
//     }

//     document.addEventListener("keydown", handleKeyDown);

//     return () => {
//       document.removeEventListener("keydown", handleKeyDown);
//     };
//   }, []);

//   initialize(backendGoogleAnalitycs || "xxxxxxxxxxxxxx");

//   HomeHotKeys({ push });

//   const close = () => {
//     notification.destroy();
//   };

//   const getSubmodules = (moduleName: any) => {
//     const module: any = getPermisosByModule(moduleName, undefined);
//     return module && module.map((permiso: any) => permiso.submodulo);
//   };

//   const createShortcutData = (
//     key: string,
//     name: string,
//     shortCut: string,
//     condition: boolean
//   ) => {
//     return condition ? { key, name: t(name), shortCut } : null;
//   };

//   const openNotification = () => {
//     const key = `open${Date.now()}`;

//     const getName = payload.permisos.modulos.map(
//       (item: any) => item.short_name
//     );

//     const getclients = getSubmodules("clients");
//     const getmiscellaneous = getSubmodules("miscellaneous");
//     const getusuarios = getSubmodules("usuarios");
//     const getassets = getSubmodules("assets");
//     const gettransacctions = getSubmodules("transacctions");
//     const getVertebots = getSubmodules("vertebots");

//     const data = [
//       { key: "0", name: t("viewShortcuts"), shortCut: "SHIFT + TAB" },
//       { key: "1", name: t("closeShortcuts"), shortCut: "SHIFT + 1" },
//       { key: "2", name: t("beginning"), shortCut: "SHIFT + Q" },
//       { key: "28", name: t("updateTable"), shortCut: "SHIFT + ESPACIO" },
//       createShortcutData(
//         "3",
//         "point",
//         "SHIFT + J",
//         getclients && getclients.includes("place")
//       ),
//       createShortcutData(
//         "4",
//         "account",
//         "SHIFT + C",
//         getclients && getclients.includes("account")
//       ),
//       createShortcutData(
//         "5",
//         "groups",
//         "SHIFT + G",
//         getclients && getclients.includes("regional")
//       ),
//       createShortcutData(
//         "6",
//         "domains",
//         "SHIFT + D",
//         getmiscellaneous && getmiscellaneous.includes("domain")
//       ),
//       createShortcutData(
//         "27",
//         "rates",
//         "SHIFT + 4",
//         getmiscellaneous && getmiscellaneous.includes("rates")
//       ),
//       createShortcutData(
//         "7",
//         "transactions",
//         "SHIFT + T",
//         gettransacctions && gettransacctions.includes("transactions")
//       ),
//       createShortcutData(
//         "8",
//         "serviceProvider",
//         "SHIFT + S",
//         getmiscellaneous && getmiscellaneous.includes("marketer")
//       ),
//       createShortcutData(
//         "9",
//         "entities",
//         "SHIFT + E",
//         getmiscellaneous && getmiscellaneous.includes("bank")
//       ),
//       createShortcutData(
//         "10",
//         "paymentMethods",
//         "SHIFT + M",
//         getmiscellaneous && getmiscellaneous.includes("payment")
//       ),
//       createShortcutData(
//         "11",
//         "money",
//         "SHIFT + X",
//         gettransacctions && gettransacctions.includes("fund")
//       ),
//       createShortcutData(
//         "12",
//         "ResourceSources",
//         "SHIFT + O",
//         gettransacctions && gettransacctions.includes("cashsource")
//       ),
//       createShortcutData(
//         "13",
//         "budget",
//         "SHIFT + R",
//         gettransacctions && gettransacctions.includes("budget")
//       ),
//       createShortcutData(
//         "14",
//         "user",
//         "SHIFT + U",
//         getusuarios && getusuarios.includes("users")
//       ),
//       createShortcutData(
//         "15",
//         "bill",
//         "SHIFT + V",
//         getName && getName.includes("facturas")
//       ),
//       createShortcutData(
//         "16",
//         "payments",
//         "SHIFT + P",
//         getName && getName.includes("Pagos")
//       ),
//       createShortcutData(
//         "17",
//         "audits",
//         "SHIFT + H",
//         getName && getName.includes("Auditorias")
//       ),
//       createShortcutData(
//         "18",
//         "loads",
//         "SHIFT + A",
//         getassets && getassets.includes("loads")
//       ),
//       createShortcutData(
//         "19",
//         "meter",
//         "SHIFT + W",
//         getassets && getassets.includes("meters")
//       ),
//       { key: "20", name: t("search"), shortCut: "SHIFT + L" },
//       createShortcutData(
//         "21",
//         "reportDownloads",
//         "SHIFT + Y",
//         getName && getName.includes("Descargas")
//       ),
//       createShortcutData(
//         "22",
//         "company",
//         "SHIFT + K",
//         getName && getName.includes("Compañias")
//       ),
//       createShortcutData(
//         "23",
//         "roles",
//         "SHIFT + F",
//         getusuarios && getusuarios.includes("rols")
//       ),
//       createShortcutData(
//         "24",
//         "invoiceDashboard",
//         "SHIFT + I",
//         getName && getName.includes("Dashboard facturas")
//       ),
//       createShortcutData(
//         "25",
//         "dashboardPayments",
//         "SHIFT + N",
//         getName && getName.includes("Dashboard pagos")
//       ),
//       createShortcutData(
//         "25",
//         "Vertebots",
//         "SHIFT + B",
//         getVertebots && getVertebots.includes("vbots")
//       ),
//       createShortcutData(
//         "26",
//         "invoiceDashboard",
//         "SHIFT + 2",
//         getVertebots && getVertebots.includes("bdownloads")
//       ),
//       createShortcutData(
//         "27",
//         "invoice_search",
//         "SHIFT + 3",
//         getName && getName.includes("Busqueda de facturas")
//       ),
//     ].filter(Boolean);
//     const btn = (
//       <Button
//         type="primary"
//         size="small"
//         onClick={() => notification.destroy()}
//       >
//         Confirmar
//       </Button>
//     );

//     notification.config({
//       maxCount: 1,
//     });

//     notification.open({
//       message: "ATAJOS DE TECLADO",
//       duration: 5,
//       icon: <span className="material-icons">shortcut</span>,
//       style: {
//         width: 500,
//         marginTop: "10px",
//       },
//       description: (
//         <div className="scroll-div-users">
//           <Row gutter={[8, 8]}>
//             {data.map((item: any) => (
//               <Col key={item.key} span={12}>
//                 <Paragraph>
//                   <Paragraph style={{ fontSize: "0.9rem" }}>
//                     {item.name}
//                   </Paragraph>
//                   <Tag style={{ fontSize: "0.9rem" }} color="blue">
//                     {item.shortCut ?? ""}
//                   </Tag>
//                 </Paragraph>
//               </Col>
//             ))}
//           </Row>
//         </div>
//       ),
//       btn,
//       key,
//       onClose: close,
//     });
//   };

//   useHotkeys("shift+1", () => {
//     notification.destroy();
//   });
//   useHotkeys("shift+tab", () => {
//     openNotification();
//   });

//   const filteredItems = filterItems(
//     [
//       {
//         heading: "Modulos",
//         id: "modulos",
//         items: payload?.permisos?.modulos?.map((item: any) => {
//           return {
//             id: item.id,
//             children: item.short_name,
//             //href: item.path,
//             // icon: <span className="material-icons">{item.icon}</span>,
//             onClick: () => {
//               push(`${item.path}`);
//               //setPage("root");
//             },
//           };
//         }),
//       },
//     ],
//     search
//   );

//   return (
//     <>
//       <Layout style={{ maxWidth: "100%" }}>
//         <Sider
//           width={60}
//           style={{ overflowY: "auto", margin: 0 }}
//           collapsedWidth={60}
//           collapsed={true}
//         >
//           <Sidebar />
//         </Sider>
//         <Layout style={{ minHeight: "100vh", maxWidth: "100%" }}>
//           <Content>
//             <Card size="small">
//               <Card.Grid
//                 hoverable={false}
//                 style={{
//                   minWidth: "100%",
//                   maxWidth: "100%",
//                   minHeight: "95vh",
//                   //overflowY: "auto",
//                   padding: 10,
//                   margin: 0,
//                 }}
//               >
//                 <Row gutter={[4, 4]}>
//                   <Col span={23}>
//                     <Breadcrumb>
//                       <Breadcrumb.Item onClick={() => push("/home")}>
//                         <Space size="small" align="baseline">
//                           <HomeOutlined
//                             style={{ fontSize: "0.9rem", cursor: "pointer" }}
//                           />
//                           <Typography.Text
//                             style={{ fontSize: "0.9rem", cursor: "pointer" }}
//                           >
//                             {payload.company_name}
//                           </Typography.Text>
//                         </Space>
//                       </Breadcrumb.Item>
//                       <Breadcrumb.Item
//                       //onClick={() => setOpenComandPalette(true)}
//                       >
//                         <SearchOutlined
//                           style={{ fontSize: "0.9rem", cursor: "pointer" }}
//                         />
//                       </Breadcrumb.Item>
//                     </Breadcrumb>
//                   </Col>
//                   <Col span={1}>
//                     <QuestionCircleOutlined
//                       onClick={openNotification}
//                       style={{ fontSize: "0.9rem", cursor: "pointer" }}
//                     />
//                   </Col>
//                   <Col span={24}>
//                     <Outlet />
//                   </Col>
//                 </Row>
//               </Card.Grid>
//             </Card>
//           </Content>
//           <Footer style={{ margin: 5, padding: 0 }}>
//             <Row
//               justify="center"
//               style={{ margin: 0, padding: "0px 10px 10px 10px" }}
//             >
//               <Typography.Text>{`Vertebra ©${dayjs().format(
//                 "YYYY"
//               )} Todos los derechos reservados || smart-eco versión ${
//                 process.env.REACT_APP_VERSION
//               }`}</Typography.Text>
//             </Row>
//           </Footer>
//         </Layout>
//         <CommandPalette
//           onChangeSearch={setSearch}
//           onChangeOpen={setOpen}
//           search={search}
//           isOpen={open}
//           page={page}
//         >
//           <CommandPalette.Page id="root">
//             {filteredItems.length ? (
//               filteredItems.map((list: any) => (
//                 <CommandPalette.List key={list.id} heading={list.heading}>
//                   {list.items.map(({ id, ...rest }: { id: string }) => (
//                     <CommandPalette.ListItem
//                       key={id}
//                       index={getItemIndex(filteredItems, id)}
//                       {...rest}
//                     />
//                   ))}
//                 </CommandPalette.List>
//               ))
//             ) : (
//               <CommandPalette.FreeSearchAction />
//             )}
//           </CommandPalette.Page>
//         </CommandPalette>
//       </Layout>
//       {/* <CommandPalette
//         data={payload?.permisos?.modulos}
//         companies_id={payload?.companies_assing}
//       /> */}

//       {/*
//       <SmartCommandPallete
//         open={openComandPalette}
//         onClose={onCloseComandPalette}
//       /> */}
//     </>
//   );
// };

// export default Home;
