import { gql, useMutation } from "@apollo/client";

export const CHANGELANGUAJE = gql`
  mutation CHANGELANGUAJE($languaje: String!) {
    changeLenguaje(changeIdiom: { idiom: { applicationLanguage: $languaje } }) {
      token
      back_token
    }
  }
`;

const Changelanguaje = () => {
  const [mutation] = useMutation(CHANGELANGUAJE);
  return (props: any) => {
    return mutation({
      variables: props,
    });
  };
};

export default Changelanguaje;
