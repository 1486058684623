import { setSearch } from "../../store/actions/setSearch";
import store from "../../store/store";
import { gql, useQuery, useMutation } from "@apollo/client";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { getPayload, setToken, setTokenBack } from "../../utils/auth";
import { useContext } from "react";
import { AbilityContext } from "../shared/AbilityContext";
import ReactGA from "react-ga4";

const GEY_HOME_COMPANIES = gql`
  query GEY_HOME_COMPANIES($user_id: String!) {
    companiesByUser(user_id: $user_id, search: "", first: 1000, last: 0) {
      id
      name
    }
  }
`;

const CHANGE_COMPANY_HOME = gql`
  mutation CHANGE_COMPANY_HOME($company_id: String!) {
    changeCompany(company_id: $company_id) {
      token
      back_token
    }
  }
`;

export const hotKeys = ["shift+-", "shift+-"];

type NotificationType = "success" | "error";

const openNotificationWithIcon = (
  type: NotificationType,
  title: string,
  message: string,
  duration: any
) => {
  notification[type]({
    message: `${title}`,
    description: `${message}`,
    duration: duration,
  });
};

const CommantPallete = ({ push }: any) => {
  const ability: any = useContext(AbilityContext);
  const payload = getPayload();
  const { t } = useTranslation();
  const { data } = useQuery(GEY_HOME_COMPANIES, {
    fetchPolicy: "no-cache",
    variables: {
      user_id: payload.id,
    },
    onError: (error: any) =>
      openNotificationWithIcon(
        "error",
        "Oupss ha ocurrido un problema",
        error.message,
        0
      ),
  });
  // const ability: any = useContext(AbilityContext);
  const [changeCompany] = useMutation(CHANGE_COMPANY_HOME, {
    fetchPolicy: "no-cache",
  });

  const companies =
    data?.companiesByUser.map((company: any) => {
      return {
        id: company.id,
        color: "#7B8FA1",
        name: company.name,
        command() {
          localStorage.setItem("HOME_BI", "false");
          changeCompany({ variables: { company_id: company.id } })
            .then((res) => {
              ReactGA.gtag("set", "user_properties", {
                company_id: company.id,
                rol: getPayload().rol,
                company_name: company.name,
                user_id: getPayload().nickname,
                userID: getPayload().nickname,
              });
              ReactGA.gtag("event", "CHANGECOMPANY", {
                new_company_id: company.id,
                new_company_name: company.name,
                old_company_id: getPayload().company,
                old_company_name: getPayload().company_name,
              });
              setToken(res?.data?.changeCompany?.token);
              setTokenBack(res?.data?.changeCompany?.back_token);
              window.location.replace("/home");
            })
            .catch((error: any) =>
              openNotificationWithIcon(
                "error",
                "Oupss ha ocurrido un problema",
                error?.message ?? "Error",
                0
              )
            );
        },
        shortcut: `${t("company")}`,
      };
    }) ?? [];

  const acces = [
    ability.can("ACCESS", "cargue")
      ? {
          id: 1,
          color: "pink",
          name: "Carga de facturas",
          command() {
            localStorage.setItem("HOME_BI", "false");
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/load");
          },
          shortcut: "shift+v",
        }
      : undefined,
    ability.can("ACCESS", "pagos")
      ? {
          id: 2,
          color: "green",
          name: "Pagos",
          command() {
            localStorage.setItem("HOME_BI", "false");
            push("/home/pay");
          },
          shortcut: "shift+p",
        }
      : undefined,
    ability.can("ACCESS", "dashcargue") ||
    ability.can("ACCESS", "dashpayments") ||
    ability.can("ACCESS", "dashrates")
      ? {
          id: 3,
          color: "cyan",
          name: "Dashboards",
          command() {
            localStorage.setItem("HOME_BI", "false");
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/dashboards");
          },
          shortcut: "shift+i",
        }
      : undefined,
    // ability.can("ACCESS", "dashboardpayments")
    //   ? {
    //       id: 4,
    //       color: "indigo",
    //       name: "Dashborad de pago de facturas",
    //       command() {
    //         localStorage.setItem("HOME_BI", "false");
    //         localStorage.setItem("HOME_BI", "false");
    //         store.dispatch(setSearch({ type: "totalReset", payload: {} }));
    //         push("/home/statuspayments");
    //       },
    //       shortcut: "shift+n",
    //     }
    //   : undefined,
    ability.can("ACCESS", "marketer")
      ? {
          id: 5,
          color: "red",
          name: "Prestador de servicios",
          command() {
            localStorage.setItem("HOME_BI", "false");
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/miscellaneous/marketer");
          },
          shortcut: "shift+s",
        }
      : undefined,
    ability.can("ACCESS", "bank")
      ? {
          id: 6,
          color: "blue",
          name: "Entidades",
          command() {
            localStorage.setItem("HOME_BI", "false");
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/miscellaneous/bank");
          },
          shortcut: "shift+e",
        }
      : undefined,
    ability.can("ACCESS", "domain")
      ? {
          id: 7,
          color: "magenta",
          name: "Dominios",
          command() {
            localStorage.setItem("HOME_BI", "false");
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/miscellaneous/domain");
          },
          shortcut: "shift+d",
        }
      : undefined,
    ability.can("ACCESS", "payment")
      ? {
          id: 8,
          color: "geekblue",
          name: "Metodos de pago",
          command() {
            localStorage.setItem("HOME_BI", "false");
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/miscellaneous/payment");
          },
          shortcut: "shift+m",
        }
      : undefined,
    ability.can("ACCESS", "concepts")
      ? {
          id: 9,
          color: "lime",
          name: "Conceptos",
          command() {
            localStorage.setItem("HOME_BI", "false");
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/miscellaneous/concepts");
          },
          shortcut: "falta shortcut",
        }
      : undefined,
    ability.can("ACCESS", "rates")
      ? {
          id: 10,
          color: "yellow",
          name: "Tarifas",
          command() {
            localStorage.setItem("HOME_BI", "false");
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/miscellaneous/rates");
          },
          shortcut: "shift+4",
        }
      : undefined,
    ability.can("ACCESS", "settings")
      ? {
          id: 11,
          color: "purple",
          name: "Configuraciones",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/miscellaneous/settings");
          },
          shortcut: "shift+v",
        }
      : undefined,
    ability.can("ACCESS", "place")
      ? {
          id: 12,
          color: "volcano",
          name: "Puntos",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/clients/places");
          },
          shortcut: "shift+j",
        }
      : undefined,
    ability.can("ACCESS", "account")
      ? {
          id: 13,
          color: "DeepPink",
          name: "Cuentas",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/clients/accounts");
          },
          shortcut: "shift+c",
        }
      : undefined,
    ability.can("ACCESS", "regional")
      ? {
          id: 14,
          color: "pink",
          name: "Grupos",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/clients/groups");
          },
          shortcut: "shift+g",
        }
      : undefined,
    ability.can("ACCESS", "transactions")
      ? {
          id: 15,
          color: "RebeccaPurple",
          name: "Transaciones",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/cashsourse/transactions");
          },
          shortcut: "shift+t",
        }
      : undefined,
    ability.can("ACCESS", "fund")
      ? {
          id: 16,
          color: "SeaGreen",
          name: "Fondos",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/cashsourse/fund");
          },
          shortcut: "shift+x",
        }
      : undefined,
    ability.can("ACCESS", "cashsource")
      ? {
          id: 17,
          color: "Teal",
          name: "Origenes de recursos",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/cashsourse/cashsource");
          },
          shortcut: "shift+o",
        }
      : undefined,
    ability.can("ACCESS", "budget")
      ? {
          id: 18,
          color: "RosyBrown",
          name: "Presupuestos",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/cashsourse/budget");
          },
          shortcut: "shift+r",
        }
      : undefined,
    ability.can("ACCESS", "pending")
      ? {
          id: 19,
          color: "DarkSlateGray",
          name: "Facturas por pagar",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/cashsourse/pending");
          },
          shortcut: "Falta por shortcut",
        }
      : undefined,
    ability.can("ACCESS", "users")
      ? {
          id: 20,
          color: "Goldenrod",
          name: "Usuarios",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/users/users");
          },
          shortcut: "shift+u",
        }
      : undefined,
    ability.can("ACCESS", "rols")
      ? {
          id: 21,
          color: "RosyBrown",
          name: "Roles",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/users/rols");
          },
          shortcut: "Sin shortcut",
        }
      : undefined,
    ability.can("ACCESS", "company")
      ? {
          id: 22,
          color: "Navy",
          name: "Compañias",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/companies");
          },
          shortcut: "shift+k",
        }
      : undefined,
    ability.can("ACCESS", "descargas")
      ? {
          id: 23,
          color: "MediumSlateBlue",
          name: "Descargas de reportes",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/downloads");
          },
          shortcut: "shift+y",
        }
      : undefined,
    ability.can("ACCESS", "uploader")
      ? {
          id: 24,
          color: "CadetBlue",
          name: "Uploader",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/uploader");
          },
          shortcut: "shift+7",
        }
      : undefined,
    ability.can("ACCESS", "audit")
      ? {
          id: 25,
          color: "Turquoise",
          name: "Manejo de auditorias",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/audit/audit");
          },
          shortcut: "shift+h",
        }
      : undefined,
    ability.can("ACCESS", "odoo")
      ? {
          id: 26,
          color: "Teal",
          name: "Odoo",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/audit/odoo");
          },
          shortcut: "Sin shortcut",
        }
      : undefined,
    ability.can("ACCESS", "emails")
      ? {
          id: 27,
          color: "Olive",
          name: "Correos",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/audit/emails");
          },
          shortcut: "Sin shortcut",
        }
      : undefined,
    ability.can("ACCESS", "loads")
      ? {
          id: 28,
          color: "DarkMagenta",
          name: "Cargas",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/assets/loads");
          },
          shortcut: "shift+a",
        }
      : undefined,
    ability.can("ACCESS", "meters")
      ? {
          id: 29,
          color: "DarkKhaki",
          name: "Medidores",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/assets/meters");
          },
          shortcut: "shift+w",
        }
      : undefined,
    ability.can("ACCESS", "bi-reports")
      ? {
          id: 30,
          color: "OrangeRed",
          name: "Bi-reports",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/bi-reports");
          },
          shortcut: "shift+6",
        }
      : undefined,
    ability.can("ACCESS", "vbots")
      ? {
          id: 31,
          color: "Crimson",
          name: "Vertebots",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/vertebots/vbots");
          },
          shortcut: "shift+b",
        }
      : undefined,
    ability.can("ACCESS", "bdownloads")
      ? {
          id: 32,
          color: "pink",
          name: "Descarga de facturas",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/vertebots/bdownloads");
          },
          shortcut: "Sin shortcut",
        }
      : undefined,
    ability.can("ACCESS", "budgetclient")
      ? {
          id: 33,
          color: "SpringGreen",
          name: "Presupuesto de clientes",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/budgetclient");
          },
          shortcut: "shift+9",
        }
      : undefined,
    ability.can("ACCESS", "ecoeficiencia")
      ? {
          id: 34,
          color: "LightSeaGreen",
          name: "Ecoeficiencia",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/ecoeficiencia");
          },
          shortcut: "Sin shortcut",
        }
      : undefined,
    ability.can("ACCESS", "conciliations")
      ? {
          id: 35,
          color: "#1A0000",
          name: "Conciliaciones",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/cashsourse/conciliations");
          },
          shortcut: "Sin shortcut",
        }
      : undefined,
    ability.can("ACCESS", "movementsandtransfers")
      ? {
          id: 36,
          color: "#863A6F",
          name: "Movimientos y transferencias",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/cashsourse/movementsandtransfers");
          },
          shortcut: "Sin shortcut",
        }
      : undefined,
    ability.can("ACCESS", "marketer")
      ? {
          id: 37,
          color: "#6C4AB6",
          name: "Micelaneos",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/miscellaneous/marketer");
          },
          shortcut: "shift+s",
        }
      : undefined,
    ability.can("ACCESS", "place")
      ? {
          id: 38,
          color: "#474E68",
          name: "Clientes",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/clients/places");
          },
          shortcut: "shift+j",
        }
      : undefined,
    ability.can("ACCESS", "guruve")
      ? {
          id: 39,
          color: "#57CC99",
          name: "Guruve",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/guruve");
          },
          shortcut: "shift+0",
        }
      : undefined,
    ability.can("ACCESS", "guruveusers")
      ? {
          id: 40,
          color: "#57CC99",
          name: "Guruve Usuarios",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/guruve/users");
          },
          shortcut: "shift+0",
        }
      : undefined,
    ability.can("ACCESS", "guruvehistory")
      ? {
          id: 41,
          color: "#57CC99",
          name: "Guruve Historial",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/guruve/history");
          },
          shortcut: "shift+0",
        }
      : undefined,
    ability.can("ACCESS", "guruvescheduled")
      ? {
          id: 42,
          color: "#57CC99",
          name: "Guruve mensajes programados",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/guruve/schedules");
          },
          shortcut: "shift+0",
        }
      : undefined,
    ability.can("ACCESS", "guruvegroups")
      ? {
          id: 43,
          color: "#57CC99",
          name: "Guruve grupos",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/guruve/groups");
          },
          shortcut: "shift+0",
        }
      : undefined,
    ability.can("ACCESS", "guruveumessages")
      ? {
          id: 44,
          color: "#57CC99",
          name: "Guruve mensajes",
          command() {
            localStorage.setItem("HOME_BI", "false");
            store.dispatch(setSearch({ type: "totalReset", payload: {} }));
            push("/home/guruve/messages");
          },
          shortcut: "shift+0",
        }
      : undefined,
    {
      id: 45,
      color: "#2B4865",
      name: "Inicio",
      command() {
        localStorage.setItem("HOME_BI", "false");
        store.dispatch(setSearch({ type: "totalReset", payload: {} }));
        push("/home");
      },
      shortcut: "Sin shortcut",
    },
    ability.can("ACCESS", "searchBills")
      ? {
          id: 46,
          color: "green",
          name: "Busqueda de facturas",
          command() {
            localStorage.setItem("HOME_BI", "false");
            push("/home/searchbills");
          },
          shortcut: "shift+f",
        }
      : undefined,
    ability.can("ACCESS", "guruvetemplates")
      ? {
          id: 47,
          color: "#57CC99",
          name: "Guruve plantillas",
          command() {
            localStorage.setItem("HOME_BI", "false");
            push("/home/guruve/templates");
          },
          shortcut: "Sin shortcut",
        }
      : undefined,
    ability.can("ACCESS", "reports")
      ? {
          id: 48,
          color: "#2B4865",
          name: "Herramienta de reportes dinamicos",
          command() {
            localStorage.setItem("reports", "false");
            push("/home/reports");
          },
          shortcut: "Sin shortcut",
        }
      : undefined,
  ];

  const result = acces.filter((item) => item !== undefined);
  const commands: any = [...companies, ...result];
  return commands;
};

export { CommantPallete };
